import React from 'react'
import ElectionNumber from '../../components/ElectionNumber/ElectionNumber'
import resumePagePhoto from '../../assets/images/Shawn_huff_resume.jpg'
import resumeData from '../../data/resume-data.json'
import './resume.css'

export default function Resume() {
  return (
    <div className='resume-page'>
      <figure className='resume-header-photo'>
        <img src={resumePagePhoto} alt="shawn_resume.jpg" />
        <ElectionNumber />
      </figure>
      <div className='resume-page-content'>
        <h2 className='resume-page-h2'>Ansioluettelo</h2>
        <div className='fiduciary-duties'>
          <h3>Luottamustoimet</h3>
          {resumeData.iduciaryDuties.map(duty => (
            <p key={duty.title}>{duty.title}</p>
          ))}
        </div>
        <div className='schools'>
          <h3>Koulut</h3>
          {resumeData.schools.map(school => (
            <p key={school.school}>{school.school}</p>
          ))}
      </div>
      <div className='teams'>
        <h3>Urheilu-ura</h3>
        {resumeData.sportsTeams.map(team => (
          <div key={team.team} className='team-row'>
          <p className='year'>{team.year}</p>
          <p>{team.team}</p>
          </div>
        ))}
      </div>
      <div className='accomplishments'>
        <h3>Saavutukset</h3>
        {resumeData.accomplishments.map((accomplishment, i) => (
          <div key={`${accomplishment.accomplishment}, ${i}`} className='accomplishment-row'>
            <p className='year'>{accomplishment.year}</p>
            <p>{accomplishment.accomplishment}</p>
          </div>
        ))}
      </div>

      </div>
    </div>
  )
}
