import React from 'react'
import { Box, Button, ButtonGroup, IconButton, iconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './footer.css'

export default function Footer() {
  return (
    <div className='footer'>
      <IconButton>
        <a className='social-media-icon' href="https://www.facebook.com/ShawnCHuff"><FacebookIcon /></a>
      </IconButton>
      <IconButton>
        <a className='social-media-icon' href="https://twitter.com/shuff247?lang=fi"><TwitterIcon /></a>
      </IconButton>
      <IconButton>
        <a className='social-media-icon' href="https://www.instagram.com/shuff247/?hl=fi"><InstagramIcon /></a>
      </IconButton>
      <IconButton>
        <a className='social-media-icon' href="https://fi.linkedin.com/in/shawn-huff-73383b1b2"><LinkedInIcon /></a>
      </IconButton>
    </div>
  )
}
