import React from 'react'
import contactHeaderPhoto from '../../assets/images/shawn-vuoret3-large.jpg'
import './contact.css'

export default function Contact() {
  return (
    <div className='contact-page'>
      <figure>
        <img src={contactHeaderPhoto} alt="shawn.jpg" />
      </figure>
      <div className='contact-page-content'>
        <h2>Tule mukaan!</h2>
        <p>Haluatko mukaan tälle matkalle?</p>
        <p>Eduskuntavaalikampanjointi ei ole yksilölaji. Olet tervetullut vaalijoukkueeseeni vaikkapa tekemään somea, jakamaan esitteitä, keräämään varoja tai ideoimaan tempauksia tulevalle keväälle!</p>
        <p>Ota rohkeasti yhteyttä: <b>huffkampanja@gmail.com</b> ja tuo mukaan oma osaamisesi omien aikataulujesi mukaisesti. Tehdään tästä unohtumaton kampanjakokemus meille kaikille!</p>
        <p>Lahjoituslinkki: <a className='donate-link' href="https://www.vihreat.fi/ehdokkaat/?kieli=fi&vaali=eduskuntavaali-2023&alue=vp1&sukupuoli=mies&ehdokas=huff-shawn-6167" target="_blank" rel="noreferrer">Lahjoita</a></p>
        <h2>Ota yhteyttä</h2>
        <p>Jäikö jokin asia vaaliteemoissani askarruttamaan? Tahdotko esittää vastaväitteen, tuliko mieleen hyviä ideoita tai kiinnostaisiko yhteistyö?</p>
        </div>
    </div>
  )
}
