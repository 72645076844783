import React from 'react'
import Article from '../../components/Article/Article'
import familyPhoto from '../../assets/images/shawn-perhe1.jpg'
import './about.css'
import articleData from '../../data/article-data.json'
import aboutData from '../../data/about-data.json'

export default function About() {
  return (
    <div className='about-page'>
      <figure className='about-header-photo'>
        <img src={familyPhoto} alt="shawn-perhe.jpg" />
      </figure>
      <div className='about-me-content'>
        <h2 className='about-h2'>Minusta</h2>
        {aboutData.basicData.map(basicData => (
        <p key={basicData.item}>{basicData.item}</p>
        ))}
      </div>
      <div className='introduction-content'>
        <h2 className='landing-h2'>Kuka Shawn?</h2>
        {aboutData.IntroductionData.map(item => (
          <p key={item.item}>{item.item}</p>
        ))}
      </div>
      <div className='articles'>
      <h2 className='about-h2'>Mediassa</h2>
      {articleData.articles.map(article => {
        return <Article key={article.link} link={article.link} title={article.title} source={article.source} moreInfo={article.moreInfo}/>
      })}
      </div>
    </div>
  )
}
