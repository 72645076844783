import React from 'react'
import { Outlet } from 'react-router-dom'
import { Grid } from '@mui/material'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function RootLayout() {
  return (
    <div className='root-layout-page'>
      <Grid container>
        <Grid item xs={12} md={10} lg={8} sx={{margin: "auto"}}>
          <Navbar/>
          <Outlet />
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}
