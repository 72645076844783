import React from 'react';
import { Box } from '@mui/material';

import './electionNumber.css';

export default function ElectionNumber() {
  return (
    <Box>
      <Box
        sx={{ display: { xs: 'flex', sm: 'none' } }}
        className='election-number-circle-small'
      >
        198
      </Box>
      <Box
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        className='election-number-circle-large'
      >
        198
      </Box>
    </Box>
  );
}
