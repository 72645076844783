import React from 'react'
import { Button } from '@mui/material'
import parliamentElectionPagePhoto from '../../assets/images/shawn-metro2.jpg'
import './parliamentElection.css'

export default function ParliamentElection() {
  return (
    <div className='parliament-election-page'>
      <figure>
        <img src={parliamentElectionPagePhoto} alt="shawn-metro2.jpg" />
      </figure>
      <div className='parliament-election-page-content'>
        <h2>Äänestä eduskuntavaaleissa</h2>
        <p>Voit äänestää ennakkoon missä tahansa Suomessa 22.-28.3.2023. Tarvitset vain jonkin henkilöllisyystodistuksen mukaasi.</p>
        <p>Jos oleskelet ulkomailla, ennakkoäänestysaika on 22.-25.3.2023</p>
        <p>Sinulla on äänioikeus eduskuntavaaleissa, jos täytät 18 vuotta viimeistään vaalipäivänä ja olet Suomen kansalainen.</p>
        <p>Voit äänestää vain oman vaalipiirisi ehdokkaita - Shawnia siis vain, jos olet äänioikeutettu Helsingissä.</p>
      </div>
        <a className='how-to-vote-link' href="https://vaalit.fi/tietoa-eduskuntavaaleista-eri-kielilla">How to vote</a>
    </div>
  )
}
