import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from './pages/RootLayout/RootLayout';
import Landing from './pages/Landing/Landing';
import About from './pages/About/About';
import Resume from './pages/Resume/Resume';
import Contact from './pages/Contact/Contact';
import ParliamentElection from './pages/ParliamentElection/ParliamentElection'

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Landing />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/resume",
        element: <Resume />
      },
      {
        path: "/election",
        element: <ParliamentElection />
      },
      {
        path: "/contact",
        element: <Contact />
      },
    ]
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App;
