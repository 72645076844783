import React from 'react'
import './article.css'
export default function Articles({ link, source, title, moreInfo}) {
  return (
    <div className='article-component'>
        <p className='article-source'>{source}</p>
        <p>{title}</p>
      <a href={link} target="_blank" rel="noreferrer">
        <p className='article-read-more'>{moreInfo}</p>
      </a>
    </div>
  )
}
