import React from 'react'
import './landing.css'
import ElectionNumber from '../../components/ElectionNumber/ElectionNumber';
import electionThemeData from '../../data/election-theme-data.json'
import landingPagePhoto from '../../assets/images/Shawn_huff_landing.jpg';

export default function Landing() {
  return (
    <div className='landing-page'>
      <figure className='landing-header-photo'>
        <img src={landingPagePhoto} alt="shawn-landing.jpg" />
        <ElectionNumber/>
      </figure>
      <div className='landing-page-content'>
        <h1 className='landing-title'>Shawn Huff</h1>
        <div className='election-theme-page-content'>
          <h2>Vaaliteemani</h2>
          {electionThemeData.electionTheme.map(item => (
            <p key={item.item}>{item.item}</p>
          ))}
        </div>
      </div>

    </div>
  )
}
