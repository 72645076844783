import React, { useState} from 'react'
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import vihreatLogo from '../../assets/logos/vihreatLogo.jpeg'
import './navbar.css'


const pages = [
  { link: "https://www.vihreat.fi/ehdokkaat/?kieli=fi&vaali=eduskuntavaali-2023&alue=vp1&sukupuoli=mies&ehdokas=huff-shawn-6167", name: 'Lahjoita'},
  { link: "/about", name: 'Minusta'},
  { link: "/resume", name: 'Ansioluettelo'},
  { link: "/election", name: 'Eduskuntavaalit 2023'},
  { link: "/contact", name: 'Ota yhteyttä'}
]

const drawerWidth = 240

export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false)
  
  const handleOpenNavMenu = () => {
    setMobileOpen(true)
  }
  
  const handleCloseNavMenu = () => {
    setMobileOpen(false)
  }

  const drawer = (
    <Box onClick={handleCloseNavMenu} sx={{ textAlign: 'center' }}>
      <List>
        {pages.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{textAlign: "center"}}>
              <Link className='drawer-link' to={item.link}><ListItemText primary={item.name}/></Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
  
  return (
    <AppBar sx={{ backgroundColor: "transparent", boxShadow: "none"}} className="navbar" position='static'>
        <Toolbar sx={{maxWidth: "100%"}}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, justifyContent: "space-between" }}>
            <figure className='navbar-logo'>
              <Link to="/"><img className='navbar-image' src={vihreatLogo} alt="vihreatLogo.png" /></Link>
            </figure>
            <IconButton
              sx={{ color: "#006845"}}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box component="nav">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleCloseNavMenu}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginRight: 0 },
              }}
              >
                {drawer}
              </Drawer>
          </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex', flexDirection: "column" }, justifyContent: "right" }}>
              <figure className='navbar-large-screen-logo'>
                <Link to="/"><img className='navbar-image' src={vihreatLogo} alt="vihreatLogo.png" /></Link>
              </figure>
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "right"}}>
              {pages.map(page => (
                <Button 
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: 'block' }}
                >
                  <Link className='links' to={page.link}>{page.name}</Link>
                </Button>
              ))}
              </Box>
            </Box>
        </Toolbar>
    </AppBar>
  )
}
